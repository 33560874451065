import { render, staticRenderFns } from "./compL.vue?vue&type=template&id=6023f044&scoped=true"
import script from "./compL.vue?vue&type=script&lang=js"
export * from "./compL.vue?vue&type=script&lang=js"
import style0 from "./compL.vue?vue&type=style&index=0&id=6023f044&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6023f044",
  null
  
)

export default component.exports