<template>
  <div class="business_sub_page">
    <div class="topic_panel">
      <div class="topic_desc">
        吸入药物一站式服务平台
      </div>
      <div class="topic_desc_en">
        ONE-STOP SERVICE PLATFORM FOR INHALED DRUGS
      </div>
      <div class="topic_desc_line"></div>
    </div>
    <div class="main_panel">
      <div class="module_desc">
        {{thematicDesc}}
      </div>
      <div class="card_panel">
        <div class="card_item" v-for="(card,cIdx) in cards" :key="cIdx">
          <div class="card_label">
            {{card.name}}
          </div>
          <div class="card_line" v-for="(lItem,lIdx) in card.features" :key="lIdx">
            <div class="card_line_icon"></div>
            <div class="card_line_value">
              {{lItem}}
            </div>
          </div>
          <div class="card_line" v-if="card.desc">
            {{card.desc}}
          </div>
        </div>
      </div>
      <div class="info_panel">
        <div class="info_item" v-for="(iItem,iIdx) in infos" :key="iIdx">
          <div class="info_label">
            {{iItem.name}}
          </div>
          <div class="info_line" v-for="(lItem,lIdx) in iItem.detail" :key="lIdx">
            {{lItem}}
          </div>
        </div>
      </div>
      <div class="pic_panel">
        <div class="pic_line" v-for="(lItem,lIdx) in pics" :key="lIdx">
          <div class="pic_item" v-for="(pItem,pIdx) in lItem" :key="pIdx">
            <div class="pic_item_label">
              {{pItem.name}}
            </div>
            <div class="pic_item_el">
              <img :src="pItem.pic">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  components: {
  },
  // 定义属性
  data () {
    return {
      "thematicDesc":"",
      "cards":[],
      "infos":[],
      "pics":[]
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    articleInfo () {
      // let lang = this.$i18n.locale;
      return "";
    },
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 获取页面数据
    getData(){
      let _this = this;
      _this.$http.get(`${window.SEVERURL}/safeglp/generator/configs/queryByType?primaryTitle=safeFeatures&secondaryTitle=one_stopServicePlatformForInhaledDrugs`).then(res=>{
        if(res.status==200&&res.data.code==0){
          // 获取配置数据
          let jsonStr = res.data.page?res.data.page.configs:"{}";
          let jsonStrPure = jsonStr.replace(/\n/g,"").replace(/\r/g,"");
          let configData = JSON.parse(jsonStrPure); 
          
          // 处理配置数据
          _this.thematicDesc = configData.thematicDesc;
          _this.cards = configData.cards;
          _this.infos = configData.infos;
          _this.pics = configData.pics.map(arr=>{
            return arr.map(i=>{
              return Object.assign({},i,{
                pic: `${window.PICSEVERURL}/${i.pic}`
              })
            });
          })
        }else{
          _this.$notify({
            title: '失败',
            message: "吸入药物一站式服务平台数据请求失败",
            type: 'error'
          });
        }
      });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getData();
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
.business_sub_page {
  width: 100%;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .topic_panel {
    max-width: 1520px;
    min-width: 1200px;
    width: 100%;
    margin-top: 70px;
    margin-bottom: 43px;
    .topic_desc{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 42px;
      color: #222222;
      line-height: 46px;
      margin-bottom: 10px;
    }
    .topic_desc_en{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 24px;
      color: #BCBDBE;
      line-height: 28px;
      margin-bottom: 16px;
    }
    .topic_desc_line{
      width: 76px;
      height: 3px;
      background: #0086D1;
    }
  }
  .main_panel {
    max-width: 1520px;
    min-width: 1200px;
    width: 100%;
    margin-bottom: 70px;
    .module_desc{
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #333333;
      line-height: 32px;
      margin-bottom: 28px;
    }
    .card_panel{
      display: flex;
      margin-bottom: 45px;
      .card_item{
        flex: 1;
        overflow: hidden;
        margin-right: 35px;
        padding: 22px 50px;
        background: #F6F7F9;
        &:last-child{
          margin-right: 0;
        }
        .card_label{
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 24px;
          color: #0086D1;
          line-height: 53px;
          margin-bottom: 8px;
        }
        .card_line{
          display: flex;
          align-items: flex-start;
          font-weight: 400;
          font-size: 18px;
          .card_line_icon{
            width: 15px;
            height: 15px;
            background: url("./images/icon@checked.png") 0 0 no-repeat;
            background-size: 100% auto;
            background-position: center left;
            margin: 10px 7px 10px 0;
          }
          .card_line_value{
            flex: 1;
            overflow: hidden;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 18px;
            color: #333333;
            line-height: 35px;
          }
        }
      }
    }
    .info_panel{
      background: #0086D1;
      display: flex;
      padding: 50px 70px;
      box-sizing: border-box;
      margin-bottom: 44px;
      .info_item{
        flex: 1;
        overflow: hidden;
        margin-right: 70px;
        &:last-child{
          margin-right: 0;
        }
        .info_label{
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 24px;
          color: #FFFFFF;
          line-height: 53px;
          text-align: center;
        }
        .info_line{
          background: #FFFFFF;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 18px;
          color: #0086D1;
          line-height: 61px;
          margin-bottom: 8px;
          text-align: center;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
    .pic_panel{
      .pic_line{
        margin-bottom: 50px;
        display: flex;
        &:last-child{
          margin-bottom: 0;
        }
        .pic_item{
          margin-right: 70px;
          flex: 1;
          // overflow: hidden;
          &:last-child{
            margin-right: 0;
          }
          .pic_item_label{
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 24px;
            color: #0086D1;
            line-height: 53px;
          }
          .pic_item_el{
            width: 100%;
            img{
              height: 200px;
            }
          }
        }
      }
    }
  }
}
</style>